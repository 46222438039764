import { useEffect, VFC } from 'react'
import './InstallInstructions.scss'
import { useHistory, useLocation } from 'react-router-dom'
import scrollToElement from 'scroll-to-element'
import { Button } from '@paradigms-to-practices/knowhowhub-components'

const InstallInstructions: VFC = () => {
  const { hash } = useLocation()

  useEffect(() => {
    console.log(hash)
    if (hash)
      setTimeout(
        () =>
          scrollToElement(hash, {
            offset: 0,
            align: 'top',
            duration: 0,
          }),
        100
      )
  }, [hash])

  const history = useHistory()

  return (
    <div
      id='install-instructions'
      style={{
        padding: '2rem 0',
        maxWidth: '40rem',
        margin: 'auto',
        overflow: 'hidden',
        overflowY: 'auto',
      }}
    >
      <div style={{ margin: '0 2rem' }}>
        <h1>KnowHowHub Installation Instructions</h1>

        <p>
          KnowHowHub is a progressive web app (PWA), meaning it runs in your
          browser both on your desktop computer and can be installed to your
          home screen on mobile devices.
        </p>

        <p>
          The difference with progressive web apps is that they are not
          installed through the app store.
        </p>

        <p>
          Below are the instructions for installing KnowHowHub on your mobile
          devices.
        </p>

        <div
          style={{
            margin: '1rem 0',
          }}
        >
          <h2>Installation Methods</h2>
          <ul>
            <li>
              <a href='#Safari-iOS'>Using Safari on iOS</a>
            </li>
            <li>
              <a href='#Chrome-Android'>Using Chrome on Android</a>
            </li>
            <li>
              <a href='#Samsung-Android'>Using Samsung Internet on Android</a>
            </li>
            <li>
              <a href='#Firefox-Android'>Using Firefox on Android</a>
            </li>
          </ul>
        </div>

        <div className='instructions'>
          <h3 id='Safari-iOS'>
            <img
              src='/Safari browser logo.svg'
              alt='Safari Logo'
              style={{
                display: 'inline-block',
                height: '2em',
                width: '2em',
                marginRight: '1em',
              }}
            />
            Using Safari on iOS
          </h3>
          <div className='row-then-col'>
            <ul style={{ listStyle: 'number', minWidth: 230 }}>
              <li>
                <span>
                  Click the <b>Share</b> (
                </span>
                <img
                  src='/ios-share-icon.png'
                  style={{
                    width: '1em',
                    height: '1em',
                    display: 'inline-block',
                  }}
                  alt='iOS Share Icon'
                />
                <span>) button</span>
              </li>
              <li>
                Click the <b>Add to Home Screen</b> button at the bottom
              </li>
              <li>
                Click the <b>Add</b> button in the top right
              </li>
              <div style={{ margin: '1rem 0' }}>
                <Button label='Done' onClick={() => history.go(-1)} />
              </div>
            </ul>
            <img
              style={{ width: '250px' }}
              src='/install-using-safari-on-ios.gif'
              alt='Install Progressive Web App using Safari on iOS'
            />
          </div>
        </div>

        <div className='instructions'>
          <h3 id='Chrome-Android'>
            <img
              src='/Google_Chrome_icon_(September_2014).svg'
              alt='Chrome Logo'
              style={{
                display: 'inline-block',
                height: '2em',
                width: '2em',
                marginRight: '1em',
              }}
            />
            Using Chrome on Android
          </h3>
          <div className='row-then-col'>
            <ul style={{ listStyle: 'number', minWidth: 230 }}>
              <li>
                <span>
                  Click the <b>Options</b> (
                </span>
                <img
                  src='/3-vertical-dots.svg'
                  style={{
                    width: '1em',
                    height: '1em',
                    display: 'inline-block',
                  }}
                  alt='Chrome Options Button Icon'
                />
                <span>) button</span>
              </li>
              <li>
                Click the <b>Install app</b> button
              </li>
              <li>
                Click the <b>Install</b> button
              </li>
              <li>
                Drag the icon to manually position it, or click{' '}
                <b>Add to Home Screen</b> or <b>Add automatically</b>
              </li>
              <div style={{ margin: '1rem 0' }}>
                <Button label='Done' onClick={() => history.go(-1)} />
              </div>
            </ul>
            <img
              style={{ width: '250px' }}
              src='/install-using-chrome-on-android.gif'
              alt='Install Progressive Web App using Safari on iOS'
            />
          </div>
        </div>

        <div className='instructions'>
          <h3 id='Samsung-Android'>
            <img
              src='/Samsung_Internet_logo.svg'
              alt='Samsung Internet Logo'
              style={{
                display: 'inline-block',
                height: '2em',
                width: '2em',
                marginRight: '1em',
              }}
            />
            Using Samsung Internet on Android
          </h3>
          <div className='row-then-col'>
            <ul style={{ listStyle: 'number', minWidth: 230 }}>
              <li>
                <span>
                  Click the <b>Menu</b> (
                </span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  style={{
                    width: '1rem',
                    height: '1rem',
                    display: 'inline-block',
                  }}
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M4 6h16M4 12h16M4 18h16'
                  />
                </svg>
                <span>) button</span>
              </li>
              <li>
                Click the <b>Add page to</b> button in the top right
              </li>
              <li>
                Click the <b>Home screen</b> button
              </li>
              <li>
                Drag the icon to manually position it, or click{' '}
                <b>Add to Home Screen</b> or <b>Add automatically</b>
              </li>
              <div style={{ margin: '1rem 0' }}>
                <Button label='Done' onClick={() => history.go(-1)} />
              </div>
            </ul>
            <img
              style={{ width: '250px' }}
              src='/install-using-samsung-internet-on-android.gif'
              alt='Install Progressive Web App using Safari on iOS'
            />
          </div>
        </div>

        <div className='instructions'>
          <h3 id='Firefox-Android'>
            <img
              src='/Firefox Browser logo.svg'
              alt='Firefox Logo'
              style={{
                display: 'inline-block',
                height: '2em',
                width: '2em',
                marginRight: '1em',
              }}
            />
            Using Firefox on Android
          </h3>
          <div className='row-then-col'>
            <ul style={{ listStyle: 'number', minWidth: 230 }}>
              <li>
                <span>
                  Click the <b>Options</b> (
                </span>
                <img
                  src='/3-vertical-dots.svg'
                  style={{
                    width: '1em',
                    height: '1em',
                    display: 'inline-block',
                  }}
                  alt='Chrome Options Button Icon'
                />
                <span>) button</span>
              </li>
              <li>
                Click the <b>Install</b> button
              </li>
              <li>
                Drag the icon to manually position it, or click{' '}
                <b>Add to Home Screen</b> or <b>Add automatically</b>
              </li>
              <div style={{ margin: '1rem 0' }}>
                <Button label='Done' onClick={() => history.go(-1)} />
              </div>
            </ul>
            <img
              style={{ width: '250px' }}
              src='/install-using-firefox-on-android.gif'
              alt='Install Progressive Web App using Safari on iOS'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstallInstructions
