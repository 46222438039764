import React, { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { RegisterFormData } from '../../Join/Register'
import {
  Button,
  FormError,
  StringInput,
} from '@paradigms-to-practices/knowhowhub-components'
import './InitiateReset.scss'
import FormErrorComponent from '../../../../core/components/FormErrorComponent/FormErrorComponent'

export type InitiateResetFormData = { email: string }
export type InitiateResetProps = {
  error: FormError<InitiateResetFormData> | undefined
  initiateResetPassword: (data: InitiateResetFormData) => Promise<void>
  handleError: (error: Error) => void
}

// Isolate inputs and button to prevent rerendering input
const InitiateResetInputs: FC<{
  error: FormError<InitiateResetFormData> | undefined
}> = React.memo(
  ({ error }) => {
    return <StringInput name='email' type='email' error={error} required />
  },
  (prevProps, nextProps) => prevProps.error === nextProps.error
)

const InitiateReset: FC<InitiateResetProps> = props => {
  const { error, initiateResetPassword, handleError } = props
  const [running, setRunning] = useState<boolean>(false)
  const methods = useForm<RegisterFormData>()
  const onSubmit = methods.handleSubmit(async data => {
    setRunning(true)
    await initiateResetPassword(data)
      .catch(handleError)
      .finally(() => setRunning(false))
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <div className='initiate-reset'>
          <h2>Reset password</h2>
          <h4>
            Enter your email below, and we'll send you a link to reset your
            password
          </h4>
          <InitiateResetInputs error={error} />
          <p style={{ opacity: 0.5 }}>Note: emails are case sensitive</p>
          <FormErrorComponent error={error} />
          <Button
            rank='pane'
            type='submit'
            label={running ? 'Resetting Password...' : 'Reset Password'}
            isLoading={running}
          />
        </div>
      </form>
    </FormProvider>
  )
}

export default InitiateReset
