import './FormErrorComponent.scss'
import React from 'react'
import type {
  FormDataBase,
  FormError,
} from '@paradigms-to-practices/knowhowhub-components'

export type FormErrorComponentProps<FD extends FormDataBase> = {
  error?: FormError<FD>
}

function FormErrorComponent<FD extends FormDataBase>(
  props: FormErrorComponentProps<FD>
) {
  const { error } = props
  return (
    <span className='error-message'>
      {error && (error.displayMessage || 'An error occurred')}
    </span>
  )
}

export default FormErrorComponent
