import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

// Additional for Authenticated Apollo
import { onError } from 'apollo-link-error'
import { ApolloLink, Observable } from 'apollo-link'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import {
  getAccessToken,
  getRefreshToken,
  handleCantRefresh,
  setAccessToken,
  validAccessToken,
} from '@paradigms-to-practices/knowhowhub-components'
import { refreshUrl } from 'src/core/constants/config'

// Authenticated Apollo
const cache = new InMemoryCache({ resultCaching: true })
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle
      Promise.resolve(operation)
        .then(operation => {
          const token = getAccessToken()
          if (token) {
            operation.setContext({
              headers: { authorization: `Bearer ${token}` },
            })
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))
      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

export const client = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'access_token',
      isTokenValidOrUndefined: validAccessToken,
      fetchAccessToken: () =>
        fetch(refreshUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getRefreshToken()}`,
          },
        }),
      handleFetch: accessToken => setAccessToken(accessToken),
      handleError: handleCantRefresh,
    }),
    onError(({ graphQLErrors, networkError }) => {
      console.log(graphQLErrors)
      console.log(networkError)
    }),
    requestLink,
    new HttpLink({
      uri: `https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/app/${
        process.env.NODE_ENV === 'development'
          ? 'knowhowhub-dev-ppvqw'
          : process.env.REACT_APP_REALM_APP_ID
      }/graphql`,
      credentials: 'include',
    }),
  ]),
  cache,
})
