import React, { FC, VFC } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Trail } from 'src/core/graphql/graphql.generated'
import { LearnerDashboardProps } from '../LearnerDashboard'
import LearnerDashboardThings from '../LearnerDashboardThings'
import { byOrder } from '../../../../core/util/sorting'
import {
  ErrorComponent,
  List,
  LoadingList,
  useKhh,
} from '@paradigms-to-practices/knowhowhub-components'
import './fullLearnerDashboard.css'

const EmptyDetails: VFC = () => (
  <div
    id='no-bundle-selected'
    className='flex text-xl h-full justify-center items-center text-gray-500'
  >
    <h2>Select a bundle to view its things</h2>
  </div>
)

const FullLearnerDashboard: FC<LearnerDashboardProps> = ({
  useLearnerState,
}) => {
  const { bundles, fetchBundles } = useLearnerState()
  const history = useHistory()
  const location = useLocation()

  const idMatch = useRouteMatch<{ id: string }>(`/learner/:id`)?.params.id

  const { organization } = useKhh()

  if (bundles instanceof Error) return <ErrorComponent />

  const selectedBundle =
    bundles?.find((bundle: Trail) => bundle._id === idMatch) || undefined

  const highlightedId: string | undefined =
    new URLSearchParams(location.search).get('selected') || undefined
  const highlighted: Trail | undefined = bundles?.find(
    (bundle: Trail) => bundle._id === highlightedId
  )

  return (
    <div className='flex flex-row h-full w-full'>
      <div className='flex flex-col w-1/2 relative h-full'>
        <div className='top-bar pl-4 lg:pl-24 xl:pl-44 pr-4'>
          <svg
            className='inline flex-shrink-0 h-12 w-6 mr-4'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='var(--primary)'
            aria-hidden='true'
          >
            <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z' />
          </svg>
          {organization?.hubName ?? 'The Hub'}
        </div>
        <div className='bundles-list left-margin'>
          {!bundles ? (
            <LoadingList />
          ) : (
            <List<Trail>
              selected={selectedBundle}
              highlighted={highlighted}
              items={bundles.sort(byOrder)}
              onClick={(t: Trail) => history.replace(`/learner/${t._id}`)}
              refresh={fetchBundles}
              descriptionFunc={(t: Trail) => <span>{t.description}</span>}
            />
          )}
        </div>
      </div>
      <div className='flex flex-col w-1/2 h-full border-l border-gray-200'>
        <div
          className='top-bar pl-4 lg:pr-20 xl:pr-40'
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          onClick={() => history.replace('/learner')}
        >
          {selectedBundle?.title ?? 'Things'}
        </div>
        <div className='things-list right-margin'>
          {!idMatch ? (
            <EmptyDetails />
          ) : !bundles ? (
            <LoadingList />
          ) : (
            <LearnerDashboardThings useLearnerState={useLearnerState} />
          )}
        </div>
      </div>
    </div>
  )
}

export default FullLearnerDashboard
