import React, { FC, useCallback, useEffect, useRef, VFC } from 'react'
import './searchBar.css'
import {
  Card,
  Loading,
  useKhh,
} from '@paradigms-to-practices/knowhowhub-components'
import { useHistory, useLocation } from 'react-router-dom'
import { machine, SearchResultItem } from './machine'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { AnimateSharedLayout, motion } from 'framer-motion'
import { useMachine } from 'react-robot'
import _ from 'lodash'
import { useIsMobile } from '../../../../core/hooks/useIsMobile'
import { getBreakpointFromWidth } from '../../../../core/hooks/useBreakpoint'
import { widthStore } from '../../../../core/hooks/useWidth'
import OrganizationLogo from '../../profile/OrganizationLogo'

const SearchIcon: FC<{ searching: boolean }> = ({ searching }) =>
  searching ? (
    <Loading size={24} small />
  ) : (
    <motion.svg
      id='search-icon'
      className='opacity-50 flex-shrink-0'
      fill='none'
      stroke='currentColor'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      width={24}
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
      />
    </motion.svg>
  )

const BackIcon: VFC = () => (
  <motion.svg
    id='back-icon'
    className='text-gray-500 w-6 h-6'
    fill='none'
    stroke='currentColor'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M15 19l-7-7 7-7'
    />
  </motion.svg>
)

const WarningIcon: VFC = () => (
  <motion.svg
    className='w-6 h-6'
    fill='none'
    stroke='currentColor'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
    />
  </motion.svg>
)

const SearchResults: FC<{
  results: SearchResultItem[]
  close: () => void
  initial: object | false
  searching?: true
}> = ({ results, close, initial, searching }) => {
  const history = useHistory()
  const isLearnerDashboard = useLocation().pathname.startsWith('/learner')

  return (
    <motion.ul
      layout
      initial={initial}
      animate={{ opacity: searching ? 0.5 : 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0 }}
      transition={{ duration: 0.1 }}
      className='search-results'
    >
      {results.length === 0 ? (
        searching ? (
          <div>Searching...</div>
        ) : (
          <motion.div key='no-results' initial={false} className='no-results'>
            We couldn't find anything matching your search.
          </motion.div>
        )
      ) : (
        results.map(result => (
          <Card
            isHighlighted={false}
            cardTag='search'
            key={result._id}
            item={{
              ...result,
              badges: [
                {
                  themeColor:
                    result.type === 'nugget' ? 'secondary' : 'primary',
                  label:
                    result.type === 'nugget'
                      ? 'Thing'
                      : result.type === 'trail'
                      ? 'Bundle'
                      : 'Action',
                },
              ],
            }}
            isSelected={false}
            onClick={() => {
              let path = ''
              if (isLearnerDashboard) {
                path += `/learner`
                if (result.type === 'nugget') path += `/${result.trail!}`
                path += `?selected=${result._id}`
              } else {
                path += `/teacher/organization/${result.organization}`
                path +=
                  result.type === 'nugget'
                    ? `/trail/${result.trail!}/nugget/${result._id}/edit`
                    : `/trail/${result._id}/edit`
              }
              history.push(path)
              close()
            }}
          />
        ))
      )}
    </motion.ul>
  )
}

const SearchBar: FC<{ shadow?: true }> = ({ shadow }) => {
  const [current, send] = useMachine(machine),
    isOpen = !(current.name === 'closed')

  const isMobile = useIsMobile()
  const checkIfMobile = () => {
    const bp = getBreakpointFromWidth(widthStore.getState().width)
    return bp === 'sm'
  }
  const inputRef = useRef<HTMLInputElement>(null)

  const { user } = useKhh()
  const { admin } = user!

  const openSearch = useCallback(() => {
    const query = inputRef.current?.value
    send(query && query.length > 0 ? { type: 'search', query, admin } : 'open')
    inputRef.current?.select()
  }, [send, inputRef, admin])
  const closeSearch = useCallback(() => {
    send('close')
    inputRef.current?.blur()
  }, [send, inputRef])

  // Close search on desktop when user clicks outside search bar
  const onTriggered = () => !checkIfMobile() && closeSearch()
  const ref = useDetectClickOutside({ onTriggered })

  // Toggle search on CTRL+K || CMD+K
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
        ;(isOpen ? closeSearch : openSearch)()
        e.preventDefault()
        return false
      }
      if (e.key === 'Escape') {
        if (isOpen) closeSearch()
        e.preventDefault()
        return false
      }
    }
    document.addEventListener('keydown', handleKeyDown, { capture: true })
    return () =>
      document.removeEventListener('keydown', handleKeyDown, { capture: true })
  }, [send, current, isOpen, closeSearch, openSearch])

  // const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

  const handleInputChange = _.throttle(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const query = e.target?.value
      if (query?.length > 0) send({ type: 'search', query, admin })
      else send('clear')
    },
    500,
    { leading: false }
  )

  const displayMode = isOpen ? (isMobile ? 'full' : 'partial') : 'closed'

  return (
    <AnimateSharedLayout>
      <div id='search' className={`search ${displayMode}`}>
        <motion.div
          className={`search-body ${shadow ? 'with-shadow' : ''}`}
          layout
          ref={ref}
          onClick={() => !isOpen && openSearch()}
        >
          <motion.div className='search-header'>
            {
              {
                full: (
                  <motion.div onClick={closeSearch} layout>
                    <BackIcon />
                  </motion.div>
                ),
                partial: (
                  <motion.div layout>
                    <SearchIcon searching={current.name === 'searching'} />
                  </motion.div>
                ),
                closed: (
                  <motion.div layout>
                    <SearchIcon searching={current.name === 'searching'} />
                  </motion.div>
                ),
              }[displayMode]
            }
            <motion.input
              id='search-input'
              layout
              placeholder='Search'
              className='ml-2 bg-transparent'
              ref={inputRef}
              onChange={handleInputChange}
            />
            <motion.div className='flex flex-shrink-0' layout>
              {isMobile && !isOpen && <OrganizationLogo size={44} />}
            </motion.div>
          </motion.div>
          {
            {
              closed: null,
              idle: (
                <motion.div
                  id='waiting-for-query'
                  layout
                  className='search-details py-8 px-4'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.3 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3, delay: 0.15 }}
                >
                  Type your query above to start searching
                </motion.div>
              ),
              results: (
                <SearchResults
                  initial={false}
                  results={current.context.results}
                  close={closeSearch}
                />
              ),
              searching: (
                <SearchResults
                  searching
                  initial={{
                    opacity: current.context.results.length > 0 ? 1 : 0,
                    scale: 0,
                  }}
                  results={current.context.results}
                  close={closeSearch}
                />
              ),
              error: (
                <motion.div layout className='search-details'>
                  <WarningIcon /> Woops! Something went wrong...{' '}
                  {/*TODO: Better error handling*/}
                </motion.div>
              ),
            }[current.name]
          }
        </motion.div>
      </div>
    </AnimateSharedLayout>
  )
}

export default SearchBar
