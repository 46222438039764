import { createMachine, state, transition } from 'robot3'

export const resetMachine = createMachine<unknown, {}>({
  init: state(
    transition('initiateReset', 'initiateReset'),
    transition('confirmReset', 'confirmReset')
  ),
  initiateReset: state(transition('resetInitiated', 'initiateSuccess')),
  initiateSuccess: state(),
  confirmReset: state(transition('resetConfirmed', 'resetSuccess')),
  resetSuccess: state(),
})
