import React, { useState, VFC } from 'react'
import { useHistory } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import './navbar.css'
import ProfileDropdown from '../profile/ProfileDropdown'
import SearchBar from '../search/SearchBar'
import OrganizationLogo from '../profile/OrganizationLogo'
import Drawer from '../drawer/Drawer'
import { useIsMobile } from '../../../core/hooks/useIsMobile'
import { useBreakpoint } from '../../../core/hooks/useBreakpoint'

const MobileNavbar: VFC = () => {
  const [drawer, setDrawer] = useState<boolean>(false)

  return (
    <>
      {/*<AnimatePresence>*/}
      {drawer && (
        <motion.div
          initial={{ translateX: '-16rem' }}
          animate={{ translateX: '0rem' }}
          exit={{ translateX: '-16rem' }}
          transition={{ duration: 0.2 }}
          className='drawer-container'
        >
          <Drawer close={() => setDrawer(false)} />
        </motion.div>
      )}
      {/*</AnimatePresence>*/}
      <div className='mobile-navbar bottom-shadow'>
        <button
          id='drawer-toggle'
          className='inline-flex items-center justify-center p-2 px-4 rounded-md text-gray-50 hover:text-white focus:outline-none focus:text-white transition duration-150 ease-in-out'
          aria-label='Main menu'
          aria-expanded='false'
          onClick={() => setDrawer(!drawer)}
        >
          {drawer ? (
            <svg className='block h-6 w-6' fill='none' viewBox='0 0 24 24'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          ) : (
            <svg className='block h-6 w-6' fill='none' viewBox='0 0 24 24'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M4 6h16M4 12h16m-16 6h16'
              />
            </svg>
          )}
        </button>
        <SearchBar />
      </div>
    </>
  )
}

const FullNavbar: VFC = () => {
  const history = useHistory(),
    breakpoint = useBreakpoint()
  const noSpacer = breakpoint === 'lg' || breakpoint === 'xl'

  return (
    <div className={`full-navbar bottom-shadow ${noSpacer ? 'no-spacer' : ''}`}>
      <div onClick={() => history.push('/')} className='centered'>
        <OrganizationLogo size={44} />
      </div>
      {!noSpacer && <div />}
      <SearchBar shadow />
      <ProfileDropdown />
    </div>
  )
}

const Navbar: VFC = () => {
  const isMobile = useIsMobile()
  return isMobile ? <MobileNavbar /> : <FullNavbar />
}

export default Navbar
