import './ConfirmReset.scss'
import React, { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  FormError,
  PasswordInput,
} from '@paradigms-to-practices/knowhowhub-components'
import { SetPasswordButton } from '../../SetPasswordButton'
import FormErrorComponent from '../../../../core/components/FormErrorComponent/FormErrorComponent'

export type ConfirmResetFormData = { ['new-password']: string }
export type ConfirmResetProps = {
  error: FormError<ConfirmResetFormData> | undefined
  confirmResetPassword: (data: ConfirmResetFormData) => Promise<void>
  handleError: (error: Error) => void
}
const ConfirmReset: FC<ConfirmResetProps> = props => {
  const { error, confirmResetPassword, handleError } = props
  const [running, setRunning] = useState<boolean>(false)
  const methods = useForm<ConfirmResetFormData>()
  const onSubmit = methods.handleSubmit(async data => {
    setRunning(true)
    await confirmResetPassword(data)
      .catch(handleError)
      .finally(() => setRunning(false))
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <div className='confirm-reset'>
          <h2>Set new password</h2>
          <h4>Enter your new password below</h4>
          <PasswordInput />
          <FormErrorComponent error={error} />
          <SetPasswordButton running={running} />
        </div>
      </form>
    </FormProvider>
  )
}
export default ConfirmReset
