import React, { FC, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useIsMobile } from '../../../core/hooks/useIsMobile'
import MobileLearnerDashboard from './MobileLearnerDashboard'
import FullLearnerDashboard from './FullLearnerDashboard'
import {
  useLearnerState as liveUseLearnerState,
  UseLearnerStore,
} from './store'
import {
  ErrorComponent,
  Loading,
} from '@paradigms-to-practices/knowhowhub-components'

export type LearnerDashboardProps = { useLearnerState: UseLearnerStore }

const useSelectedBundleId = () => {
  return (
    useRouteMatch<{ trailId: string }>(`/learner/:trailId`)?.params.trailId ||
    undefined
  )
}

const useFetchBundlesIfHaveNotBeenFetched = (
  useLearnerState: UseLearnerStore
) => {
  const { bundles, fetchBundles } = useLearnerState()
  const bundlesHaveNotBeenFetched = bundles === undefined
  const fetchBundlesIfHaveNotBeenFetched = () => {
    if (bundlesHaveNotBeenFetched) fetchBundles().then()
  }
  useEffect(fetchBundlesIfHaveNotBeenFetched, [bundles, fetchBundles])
}

const useFetchThingsForThisBundleIfHaveNotFetched = (
  useLearnerState: UseLearnerStore
) => {
  const { things, fetchThings } = useLearnerState()
  const selectedBundleId = useSelectedBundleId()
  const thingsForThisBundleHaveNotBeenFetched =
    selectedBundleId && things?.[selectedBundleId] === undefined
  const fetchThingsForThisBundleIfHaveNotFetched = () => {
    if (thingsForThisBundleHaveNotBeenFetched)
      fetchThings(selectedBundleId).then()
  }
  useEffect(fetchThingsForThisBundleIfHaveNotFetched, [
    things,
    selectedBundleId,
  ])
}

const useRemoveBundleFromPathIfBundlesAreFetchedAndTargetNotPresent = (
  useLearnerState: UseLearnerStore
) => {
  const { bundles } = useLearnerState()
  const history = useHistory()
  const selectedBundleId = useSelectedBundleId()

  const bundleIsSelectedAndBundlesAreFetched =
    selectedBundleId && Array.isArray(bundles)
  if (!bundleIsSelectedAndBundlesAreFetched) return

  const selectedBundleIsNotInBundles =
    bundles.find(bundle => bundle._id === selectedBundleId) === undefined
  if (selectedBundleIsNotInBundles) history.push('/learner')
}

export const LearnerDashboard: FC<{ useLearnerState: UseLearnerStore }> = ({
  useLearnerState = liveUseLearnerState,
}) => {
  const { bundles } = useLearnerState()
  const isMobile = useIsMobile()

  useFetchBundlesIfHaveNotBeenFetched(useLearnerState)
  useFetchThingsForThisBundleIfHaveNotFetched(useLearnerState)
  useRemoveBundleFromPathIfBundlesAreFetchedAndTargetNotPresent(useLearnerState)

  // if (!bundles) return <Loading />
  if (bundles instanceof Error) return <ErrorComponent />

  const SizedLearnerDashboard = isMobile
    ? MobileLearnerDashboard
    : FullLearnerDashboard
  return <SizedLearnerDashboard useLearnerState={useLearnerState} />
}
