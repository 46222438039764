import React from 'react'
import { Link } from 'react-router-dom'
import { khhBranding } from 'src/core/constants/config'

const Page = () => {
  return (
    <div className='relative bg-gray-50'>
      <div className='relative bg-white shadow'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6'>
          <div className='flex justify-between items-center py-6 md:justify-start md:space-x-10'>
            <div className='flex items-center flex-shrink-0 px-5'>
              <img
                className='mx-auto h-12 w-auto'
                src={'./logos/khh-full.svg'}
                alt='KnowHowHub'
              />
            </div>
            <div className='md:flex items-center justify-end md:flex-1 xl:w-0'>
              <span className='inline-flex rounded-md shadow'>
                <Link
                  to={'/login'}
                  style={{ background: khhBranding.secondary, color: 'white' }}
                  className='inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md bg-white hover:text-green-700 focus:outline-none focus:border-yellow-300 focus:shadow-outline-yellow active:bg-gray-50 active:text-yellow-700 transition duration-150 ease-in-out'
                >
                  Log in
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <main className='xl:relative'>
        <div className='mx-auto max-w-7xl w-full pt-12 pb-20 text-center xl:py-36 xl:text-left'>
          <div className='px-4 xl:w-1/2 sm:px-8 xl:pr-16'>
            <h1 className='text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl xl:text-5xl'>
              <span className='block'>Knowledge to enrich</span>
              <span className='block' style={{ color: khhBranding.secondary }}>
                your organization
              </span>
            </h1>
            <p className='mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl'>
              Use KnowHowHub to power training information aggregation and
              distribution throughout your organization. Never get lost again
              with paper guides, folders or broken shared links.
            </p>
            <div className='mt-10 sm:flex sm:justify-center xl:justify-start'>
              <div className='rounded-md shadow'>
                <Link
                  to='/login'
                  className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white hover:bg-green-700 md:py-4 md:text-lg md:px-10'
                  style={{ backgroundColor: khhBranding.secondary }}
                >
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='relative w-full h-64 sm:h-72 md:h-96 xl:absolute xl:inset-y-0 xl:right-0 xl:w-1/2 xl:h-full'>
          <img
            className='absolute inset-0 w-full h-full object-cover'
            src='https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80'
            alt=''
          />
        </div>
      </main>
    </div>
  )
}

export default Page
