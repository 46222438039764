import React, { useEffect, useState, VFC } from 'react'
import {
  FormError,
  Loading,
  useKhh,
} from '@paradigms-to-practices/knowhowhub-components'
import InitiateReset, { InitiateResetFormData } from './InitiateReset'
import Pane from '../../../core/components/Pane'
import ConfirmReset, { ConfirmResetFormData } from './ConfirmReset'
import ResetSuccess from './ResetSuccess'
import { useMachine } from 'react-robot'
import ResetInitiated from './ResetInitiated'
import './ResetPassword.scss'
import { resetMachine } from './machine'
import { useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

const ResetPassword: VFC = () => {
  const { initiateResetPassword, confirmResetPassword } = useKhh()
  const [initiateError, setInitiateError] = useState<
    FormError<InitiateResetFormData> | undefined
  >(undefined)
  const [confirmError, setConfirmError] = useState<
    FormError<ConfirmResetFormData> | undefined
  >(undefined)
  const [current, send] = useMachine(resetMachine)
  const location = useLocation(),
    params = new URLSearchParams(location.search)
  const token = params.get('t'),
    tokenId = params.get('tid')

  useEffect(() => {
    if (token && tokenId) return send('confirmReset')
    return send('initiateReset')
    // eslint-disable-next-line -- Only want to run on first render
  }, [])

  console.log(current.name)

  return (
    <Pane>
      {(() => {
        switch (current.name) {
          case 'init':
            return <Loading />
          case 'initiateReset':
            return (
              <InitiateReset
                error={initiateError}
                initiateResetPassword={({ email }: InitiateResetFormData) =>
                  initiateResetPassword(email).then(() =>
                    send('resetInitiated')
                  )
                }
                handleError={e => {
                  Sentry.captureException(e)

                  setInitiateError(
                    // TODO: Refactor to eliminate ts-ignore
                    //@ts-ignore -- This will be refactored later but is currently correct
                    'displayMessage' in e
                      ? e
                      : { ...e, displayMessage: 'An error occurred' }
                  )
                }}
              />
            )
          case 'initiateSuccess':
            return <ResetInitiated />
          case 'confirmReset':
            return (
              <ConfirmReset
                error={confirmError}
                confirmResetPassword={({
                  ['new-password']: password,
                }: ConfirmResetFormData) =>
                  confirmResetPassword(token!, tokenId!, password).then(() =>
                    send('resetConfirmed')
                  )
                }
                handleError={e => {
                  Sentry.captureException(e)
                  setConfirmError(
                    // TODO: Refactor to eliminate ts-ignore
                    //@ts-ignore -- This will be refactored later but is currently correct
                    'displayMessage' in e
                      ? e
                      : { ...e, displayMessage: 'An error occurred' }
                  )
                }}
              />
            )
          case 'resetSuccess':
            return <ResetSuccess />
        }
      })()}
    </Pane>
  )
}
export default ResetPassword
