import React, { useState, VFC } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion'
import './profileDropdown.css'
import ProfileHeader from '../ProfileHeader'
import { Buttons } from '../../Buttons'

const ProfileDropdown: VFC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const close = () => setIsOpen(false),
    toggleOpen = () => setIsOpen(!isOpen)
  const ref = useDetectClickOutside({ onTriggered: close })
  const [isHovered, setIsHovered] = useState<boolean>(false)

  return (
    <AnimateSharedLayout>
      <motion.div
        onClick={toggleOpen}
        className={`profile-dropdown`}
        animate={isOpen ? 'open' : isHovered ? 'hover' : 'closed'}
        variants={{
          closed: { scale: 1, transition: { duration: 0.1, ease: 'circOut' } },
          hover: { scale: 1.01, transition: { duration: 0.1 } },
          open: { scale: 1, transition: { duration: 0.2, ease: 'easeIn' } },
        }}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        ref={ref}
      >
        <div className='profile-dropdown-body'>
          <ProfileHeader isOpen={isOpen} className='profile-dropdown-header' />
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              transition={{ duration: 0.1 }}
              className='profile-dropdown-content'
            >
              <Buttons close={close} />
            </motion.div>
          )}
        </div>
      </motion.div>
    </AnimateSharedLayout>
  )
}
export default ProfileDropdown
