import React, { VFC } from 'react'
import Div100vh from 'react-div-100vh'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import PublicRoute from './core/components/PublicRoute'
import Home from './features/pages/Home'
import ResetPassword from './features/auth/ResetPassword'
import ProtectedRoute from './core/components/ProtectedRoute'
import { client } from './core/graphql/apolloClient'
import { ApolloProvider } from '@apollo/react-hooks'
import Navbar from './features/navigation/Navbar'
import * as Sentry from '@sentry/react'
import { LoadableTeacherDashboard } from './features/dashboard/teacher/TeacherDashboardLoadable'
import { LearnerDashboard } from './features/dashboard/learner/LearnerDashboard'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Join from './features/auth/Join'
import Login from './features/auth/Login'
import {
  ErrorComponent,
  KhhContextProvider,
} from '@paradigms-to-practices/knowhowhub-components'
import InstallInstructions from './features/pages/InstallInstructions'

const NotFound: VFC = () => {
  return <Redirect to='/' />
}

const App: VFC = () => {
  return (
    <Div100vh className='relative'>
      <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
        <ToastContainer />
        <ApolloProvider client={client}>
          <BrowserRouter>
            <KhhContextProvider
              resetClient={async () => {
                client.stop()
                await client.resetStore()
              }}
            >
              <Switch>
                <PublicRoute exact path='/' component={Home} />
                <PublicRoute exact path='/login' component={Login} />
                <PublicRoute exact path='/reset' component={ResetPassword} />
                <Route
                  exact
                  path='/install-instructions'
                  component={InstallInstructions}
                />
                <Route exact path='/join' component={Join} />
                <Route exact path='/error' component={ErrorComponent} />
                <ProtectedRoute path={['/teacher', '/learner']}>
                  <main className='bg-gray-50 relative flex flex-col h-full'>
                    <Navbar />
                    <div
                      className='relative flex-grow'
                      style={{ height: 'calc(100% - 64px)' }}
                    >
                      <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
                        <ProtectedRoute
                          path={`/teacher`}
                          component={LoadableTeacherDashboard}
                        />
                        <ProtectedRoute
                          path={`/learner`}
                          component={LearnerDashboard}
                        />
                      </Sentry.ErrorBoundary>
                    </div>
                  </main>
                </ProtectedRoute>
                <Route component={NotFound} />
              </Switch>
            </KhhContextProvider>
          </BrowserRouter>
        </ApolloProvider>
      </Sentry.ErrorBoundary>
    </Div100vh>
  )
}

export default App
