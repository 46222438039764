import React, { FC } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import {
  AnimatePresence,
  AnimateSharedLayout,
  motion,
  MotionStyle,
} from 'framer-motion'
import { Trail } from '../../../../core/graphql/graphql.generated'
import { LearnerDashboardProps } from '../LearnerDashboard'
import LearnerDashboardThings from '../LearnerDashboardThings'
import { byOrder } from '../../../../core/util/sorting'
import {
  ErrorComponent,
  List,
  LoadingList,
} from '@paradigms-to-practices/knowhowhub-components'
import './mobileLearnerDashboard.scss'

const pageStyle: MotionStyle = { position: 'absolute' }
const leftPageVariants = {
  initial: { opacity: 0, x: '-100vw' },
  in: { opacity: 1, x: 0 },
  out: { opacity: 0, x: '-100vw' },
}
const rightPageVariants = {
  initial: { opacity: 0, x: '100vw' },
  in: { opacity: 1, x: 0 },
  out: { opacity: 0, x: '100vw' },
}
const pageTransition = { ease: 'easeInOut', duration: 0.25 }

//https://heroicons.dev/?query=caret ChevronLeft
const BackButtonSvg: FC<{ className?: string }> = ({ className }) => (
  <div id='back-button' className={`h-6 w-6 ${className} m-2`}>
    <svg
      className='h-6'
      fill='none'
      stroke='#6B7280'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M10 21l-9-9 9-9'
      />
    </svg>
  </div>
)

const MobileLearnerDashboard: FC<LearnerDashboardProps> = ({
  useLearnerState,
}) => {
  const { bundles, fetchBundles } = useLearnerState()
  const currLocation = useLocation(),
    history = useHistory()

  const selectedBundleId =
    useRouteMatch<{ id: string }>(`/learner/:id`)?.params.id

  if (!bundles)
    return (
      <>
        <LoadingList />
      </>
    )
  if (bundles instanceof Error) return <ErrorComponent />

  const selectedBundle =
    bundles.find(item => item._id === selectedBundleId) || undefined
  const highlightedId: string | undefined =
    new URLSearchParams(currLocation.search).get('selected') || undefined
  const highlighted: Trail | undefined = bundles.find(
    bundle => bundle._id === highlightedId
  )

  return (
    <>
      <AnimateSharedLayout>
        <AnimatePresence initial={false}>
          <Switch location={currLocation} key={currLocation.pathname}>
            <Route exact path={`/learner`}>
              <motion.div
                id='smTrails'
                style={pageStyle}
                initial='initial'
                animate='in'
                exit='out'
                variants={leftPageVariants}
                transition={pageTransition}
                className='flex flex-col h-full w-full mobile'
              >
                <div className='mobile-bundles-list'>
                  <List<Trail>
                    {...{
                      selected: selectedBundle,
                      highlighted,
                      items: bundles.sort(byOrder),
                      selectableBySearch: true,
                      onClick: (t: Trail) => history.push(`/learner/${t._id}`),
                      titleFunc: (t: Trail) => t.title,
                      refresh: fetchBundles,
                      descriptionFunc: (t: Trail) => (
                        <span>{t.description}</span>
                      ),
                    }}
                  />
                </div>
              </motion.div>
            </Route>
            <Route exact path={`/learner/:id`}>
              <motion.div
                id='smTrail'
                style={pageStyle}
                initial='initial'
                animate='in'
                exit='out'
                variants={rightPageVariants}
                transition={pageTransition}
                className='relative flex flex-col mobile-things-list'
              >
                {selectedBundle && (
                  <>
                    <motion.div
                      className='mobile-things-list-header'
                      onClick={() => history.push('/learner')}
                    >
                      <BackButtonSvg />
                      <div
                        className='pr-4'
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          height: '1.5em',
                        }}
                        onClick={() => history.push('/learner')}
                      >
                        {selectedBundle!.title}
                      </div>
                    </motion.div>
                    <LearnerDashboardThings useLearnerState={useLearnerState} />
                  </>
                )}
              </motion.div>
            </Route>
          </Switch>
        </AnimatePresence>
      </AnimateSharedLayout>
    </>
  )
}

export default MobileLearnerDashboard
