import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import './Welcome.scss'

const Welcome: FC<{ refresh: () => Promise<any> }> = ({ refresh }) => {
  const history = useHistory()
  useEffect(() => {
    // Refresh now that user has data
    refresh().then(() => {
      setTimeout(() => history.replace('/login'), 1000)
    })
    // eslint-disable-next-line -- Intentional, first render only
  }, [])

  return (
    <div className='welcome'>
      <h2>Welcome!</h2>
      <h4>We're so happy to have you</h4>
    </div>
  )
}

export default Welcome
