import './Register.scss'
import React, { FC } from 'react'
import {
  BooleanInput,
  FormError,
  FormProps,
  PasswordInput,
} from '@paradigms-to-practices/knowhowhub-components'
import { FormProvider, useForm } from 'react-hook-form'
import { SetPasswordButton } from '../../SetPasswordButton'
import FormErrorComponent from '../../../../core/components/FormErrorComponent/FormErrorComponent'

export const registerErrorCode: unique symbol = Symbol('registerErrorCode')

export enum RegisterErrorCode {
  invalidToken = 'INVALID_TOKEN',
  networkError = 'NETWORK_ERROR',
}

export interface RegisterFormError extends FormError<RegisterFormData> {
  [registerErrorCode]: RegisterErrorCode
}

export type RegisterFormData = {
  email: string
  'new-password': string
  remember: boolean
}

export interface RegisterFormProps
  extends FormProps<RegisterFormData, RegisterFormError> {
  email: string
  registering: boolean
  handleRegister: (data: RegisterFormData) => void
}

const Register: FC<RegisterFormProps> = props => {
  const { handleRegister, email, error, registering } = props
  const methods = useForm<RegisterFormData>({ defaultValues: { email } })
  const onSubmit = methods.handleSubmit(handleRegister)

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <div className='register'>
          <h2>You're in!</h2>
          <h4>Please set your password</h4>
          <PasswordInput required />
          <BooleanInput name='remember' label='Remember Me' error={error} />
          <FormErrorComponent error={error} />
          <SetPasswordButton running={registering} />
        </div>
      </form>
    </FormProvider>
  )
}

export default Register
