import create, { UseStore } from 'zustand'
import { Nugget, Trail } from 'src/core/graphql/types.generated'
import { sdk } from '@paradigms-to-practices/knowhowhub-components'
import { byOrder, byOrderThenDelay } from 'src/core/util/sorting'

export type LearnerStoreState = {
  // bundles ===
  //    undefined: Not fetched, not fetching
  //    null: Fetching
  //    Error: Encountered error while fetching
  //    Trail[]: Has valid data
  bundles: undefined | Trail[] | null | Error

  // things[bundleId] ===
  //    undefined: Not fetched, not fetching
  //    null: Fetching
  //    Error: Encountered error while fetching
  //    Nugget[]: Has valid data
  things: Record<string, undefined | Nugget[] | null | Error>

  fetchBundles: () => Promise<void>
  fetchThings: (bundleId: string) => Promise<void>
  refresh: (bundleId?: string) => Promise<void>
}
export type UseLearnerStore = UseStore<LearnerStoreState>

export const useLearnerState: UseLearnerStore = create<LearnerStoreState>(
  set => {
    const getBundles = async () => (await sdk.getTrails()).getTrails as Trail[]
    const getThings = async (bundleId: string) =>
      (await sdk.getNuggets({ input: { trail: bundleId } }))
        .getNuggets as Nugget[]

    const fetchBundles = async () => {
      set({ bundles: null })
      try {
        set({ bundles: (await getBundles()).sort(byOrder) })
      } catch (e: any) {
        set({ bundles: e as Error })
      }
    }

    const fetchThings = async (bundleId: string) => {
      set(state => ({ things: { ...state.things, [bundleId]: null } }))
      try {
        const newThings = await getThings(bundleId)
        set(state => ({
          things: {
            ...state.things,
            [bundleId]: newThings.sort(byOrderThenDelay),
          },
        }))
      } catch (e: any) {
        set({ things: { [bundleId]: e as Error } })
      }
    }

    const refresh = async (bundleId?: string) => {
      await fetchBundles()
      if (bundleId) await fetchThings(bundleId)
    }

    return {
      bundles: undefined,
      things: {},
      fetchBundles,
      fetchThings,
      refresh,
    }
  }
)
