import './Login.scss'
import React, { FC, useState, VFC } from 'react'
import type {
  LoginFormData,
  LoginFormError,
} from '@paradigms-to-practices/knowhowhub-components'
import {
  Login as LoginForm,
  loginErrorCode,
  transformLoginError,
  useKhh,
} from '@paradigms-to-practices/knowhowhub-components'
import Pane from '../../../core/components/Pane'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

const Login: VFC = () => {
  const { logIn } = useKhh()
  const history = useHistory()
  return <LoginBody logIn={logIn} handleForgot={() => history.push('/reset')} />
}

export enum LoginErrorCode {
  invalidUsername = 'invalidUsername',
  invalidPassword = 'invalidPassword',
  networkError = 'networkError',
  unknown = 'unknown',
}

export type LoginBodyProps = {
  logIn: (
    username: string,
    password: string,
    remember: boolean
  ) => Promise<unknown>
  handleForgot: () => void
}
export const LoginBody: FC<LoginBodyProps> = props => {
  const { logIn, handleForgot } = props

  const [error, setError] = useState<LoginFormError | undefined>(undefined)
  const [loggingIn, setLoggingIn] = useState<boolean>(false)

  const handleLogin = async ({
    username,
    password,
    remember,
  }: LoginFormData) => {
    setLoggingIn(true)
    await logIn(username, password, remember)
      .finally(() => setLoggingIn(false))
      .catch(rawE => {
        const e = transformLoginError(rawE)
        if (e[loginErrorCode] !== LoginErrorCode.invalidPassword)
          Sentry.captureException(e)
        setError(e)
      })
  }

  return (
    <Pane>
      <div className='login'>
        <h2>Welcome back</h2>
        <h4>Sign in to your account</h4>
        <LoginForm
          handleLogin={handleLogin}
          handleForgot={handleForgot}
          loggingIn={loggingIn}
          error={error}
          resetError={() => setError(undefined)}
        />
      </div>
    </Pane>
  )
}

export default Login
