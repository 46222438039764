import React, { FC } from 'react'
import { Button, useKhh } from '@paradigms-to-practices/knowhowhub-components'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { useIsMobile } from '../../core/hooks/useIsMobile'
import { useLearnerState } from '../dashboard/learner/store'

export const Buttons: FC<{ close: () => void }> = ({ close }) => {
  const realmApp = useKhh()
  const { refresh } = useLearnerState()
  const history = useHistory()
  const isMobile = useIsMobile()
  const selectedBundleId =
    useRouteMatch<{ trailId: string }>(`/learner/:trailId`)?.params?.trailId ||
    undefined

  return (
    <>
      {realmApp.user?.admin && (
        <>
          <Route path='/learner'>
            <Button
              label='Switch to Teacher Dashboard'
              rank='tertiary'
              className='w-full'
              onClick={() => {
                history.push('/teacher')
                close()
              }}
            />
          </Route>
          <Route path='/teacher'>
            <Button
              label='Switch to Learner Dashboard'
              rank='tertiary'
              className='w-full'
              onClick={() => {
                history.push('/learner')
                close()
              }}
            />
            {isMobile && (
              <>
                <Button
                  label='Things Section'
                  rank='tertiary'
                  className='w-full'
                  onClick={() => {
                    history.push('/teacher')
                    close()
                  }}
                />
                <Button
                  label='Invitations Section'
                  rank='tertiary'
                  className='w-full'
                  onClick={() => {
                    history.push('/teacher/invitations')
                    close()
                  }}
                />
              </>
            )}
          </Route>
        </>
      )}
      <Button
        label='Refresh'
        rank='tertiary'
        className='w-full'
        onClick={() => {
          refresh(selectedBundleId).then()
          close()
        }}
      />
      <Button
        id='logout'
        label='Log Out'
        rank='tertiary'
        className='justify-self-end w-full'
        onClick={() =>
          realmApp.logOut().then(() => {
            history.replace('/login')
            close()
          })
        }
      />
      <Button
        id='install-instructions'
        label='Installation Instructions'
        rank='tertiary'
        className='w-full'
        onClick={() => history.push('/install-instructions')}
      />
    </>
  )
}
