export type SortReturn = 1 | 0 | -1

type HasOrder = { order?: number | null }
export const byOrder = (a: HasOrder, b: HasOrder): SortReturn =>
  sortByOrder(a, b) ?? 0

type HasDelay = { delay?: number | null }
export const byDelay = (a: HasDelay, b: HasDelay): SortReturn =>
  sortByDelay(a, b) ?? 0

export type HasDelayAndOrder = HasDelay & HasOrder
export const byOrderThenDelay = (
  a: HasDelayAndOrder,
  b: HasDelayAndOrder
): SortReturn => sortByOrder(a, b) ?? sortByDelay(a, b) ?? 0

const sortByOrder = (a: HasOrder, b: HasOrder): SortReturn | undefined =>
  sortByOptionalNum(a.order, b.order)

const sortByDelay = (a: HasDelay, b: HasDelay): SortReturn | undefined =>
  sortByOptionalNum(a.delay, b.delay)

// Can't use truthiness because 0 is falsy
const isNum = (maybeNum: number | null | undefined): maybeNum is number =>
  typeof maybeNum === 'number'

type OptNum = number | null | undefined
// Exported for testing, returns undefined instead of 0 for nullish coalescing
export const sortByOptionalNum = (a: OptNum, b: OptNum): 1 | -1 | undefined => {
  if (!isNum(a) && !isNum(b)) return undefined

  // If both are a number, sort by number ascending
  if (isNum(a) && isNum(b)) {
    if (a !== b) return a > b ? 1 : -1 // Else return undefined
  }

  // If only one is a number, it should come first
  if (isNum(a) && !isNum(b)) return 1
  if (!isNum(a) && isNum(b)) return -1
}
