import './ResetSuccess.scss'
import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export type ResetSuccessProps = {}
const ResetSuccess: FC<ResetSuccessProps> = props => {
  const history = useHistory()
  useEffect(() => {
    setTimeout(() => {
      history.replace('/login')
    }, 2000)
  }, [history])

  return (
    <div className='reset-success'>
      <h2>All set!</h2>
      <h4>Your password has been updated</h4>
    </div>
  )
}
export default ResetSuccess
