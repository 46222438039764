import { createMachine, invoke, reduce, state, transition } from 'robot3'
import type {
  AdminSearchResultItem,
  UserSearchResultItem,
} from '@paradigms-to-practices/knowhowhub-components'
import { sdk } from '@paradigms-to-practices/knowhowhub-components'

export type SearchResultItem = AdminSearchResultItem | UserSearchResultItem

const search = async ({ query, admin }: { query: string; admin: boolean }) => {
  let response: any,
    searchFunc: keyof typeof sdk = admin ? 'adminSearch' : 'userSearch'
  try {
    console.debug('Searching with query', query)
    response = await sdk[searchFunc]({ query })
    // TODO: Better error handling (toast)
    if (response.errors) throw response.errors
    return response[searchFunc]
  } catch (e) {
    console.log(JSON.stringify(e))
    throw e
  }
}

const saveSearchArgsReducer = reduce(
  (ctx: object, { query, admin }: { query: string; admin: boolean }) => ({
    ...ctx,
    query,
    admin,
  })
)

const clearResultsReducer = reduce((ctx: object) => ({
  ...ctx,
  results: [],
  error: undefined,
}))

const defaultOpenTransition = transition('open', 'idle')
const closeTransition = transition('close', 'closed')
const clearTransition = transition('clear', 'idle', clearResultsReducer)
const searchTransition = transition(
  'search',
  'searching',
  saveSearchArgsReducer
)

const invokeSearch = invoke(
  search,
  searchTransition,
  closeTransition,
  clearTransition,
  transition(
    'done',
    'results',
    reduce((ctx: object, ev: any) => {
      console.debug('Finished search', ev)
      return { ...ctx, results: ev.data }
    })
  ),
  transition(
    'error',
    'error',
    // TODO: Add better error handling
    reduce((ctx: object, { error }: { error: Error }) => ({ ...ctx, error }))
  )
)

export const machine = createMachine(
  {
    closed: state(defaultOpenTransition, searchTransition),
    idle: state(searchTransition, closeTransition),
    searching: invokeSearch,
    results: state(searchTransition, closeTransition, clearTransition),
    error: state(searchTransition, closeTransition, clearTransition),
  },
  () =>
    ({ results: [], error: undefined } as {
      results: SearchResultItem[]
      error: Error | undefined
    })
)
