import { Link } from 'react-router-dom'
import React, { FC, VFC } from 'react'
import './Pane.scss'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import OrganizationLogo from '../../../features/navigation/profile/OrganizationLogo'

const PaneAccentTriangles: VFC = () => {
  return (
    <>
      <div
        key='accent-triangle-1'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          borderTop: '40px solid var(--primary)',
          borderLeft: '40px solid var(--primary)',
          borderBottom: '40px solid transparent',
          borderRight: '40px solid transparent',
          zIndex: 2,
          height: 80,
          width: 80,
        }}
      />
      <div
        key='accent-triangle-2'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          borderTop: '45px solid var(--secondary)',
          borderLeft: '45px solid var(--secondary)',
          borderBottom: '45px solid transparent',
          borderRight: '45px solid transparent',
          zIndex: 1,
          height: 90,
          width: 90,
        }}
      />
      <div
        key='accent-triangle-3'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          borderTop: '50px solid var(--tertiary)',
          borderLeft: '50px solid var(--tertiary)',
          borderBottom: '50px solid transparent',
          borderRight: '50px solid transparent',
          zIndex: 0,
          height: 100,
          width: 100,
        }}
      />
      <div
        key='accent-triangle-4'
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          borderTop: '40px solid var(--primary)',
          borderRight: '40px solid var(--primary)',
          borderBottom: '40px solid transparent',
          borderLeft: '40px solid transparent',
          zIndex: 2,
          height: 80,
          width: 80,
        }}
      />
      <div
        key='accent-triangle-5'
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          borderTop: '45px solid var(--secondary)',
          borderRight: '45px solid var(--secondary)',
          borderBottom: '45px solid transparent',
          borderLeft: '45px solid transparent',
          zIndex: 1,
          height: 90,
          width: 90,
        }}
      />
      <div
        key='accent-triangle-6'
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          borderTop: '50px solid var(--tertiary)',
          borderRight: '50px solid var(--tertiary)',
          borderBottom: '50px solid transparent',
          borderLeft: '50px solid transparent',
          zIndex: 0,
          height: 100,
          width: 100,
        }}
      />
    </>
  )
}
const Pane: FC = ({ children }) => {
  const breakpoint = useBreakpoint()
  return (
    <div className='pane-container' style={{ zIndex: 1 }}>
      {breakpoint !== 'sm' && <PaneAccentTriangles />}
      <div className='pane'>
        <div className='logo-container'>
          <Link to='/'>
            <OrganizationLogo size={80} large />
          </Link>
        </div>
        <div className='pane-body'>{children}</div>
      </div>
    </div>
  )
}

export default Pane
