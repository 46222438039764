import { useEffect, useRef, VFC } from 'react'
import './drawerBanner.css'
import Alea from 'alea'
import { useBranding } from '@paradigms-to-practices/knowhowhub-components'

type Point = { x: number; y: number }
type Triangle = [Point, Point, Point, Point]
const triangles: Triangle[] = [
  [
    { x: 0, y: 0 },
    { x: 20, y: 20 },
    { x: 0, y: 40 },
    { x: 0, y: 0 },
  ],
  [
    { x: 0, y: 0 },
    { x: 20, y: 20 },
    { x: 40, y: 0 },
    { x: 0, y: 0 },
  ],
  [
    { x: 0, y: 40 },
    { x: 20, y: 20 },
    { x: 40, y: 40 },
    { x: 0, y: 40 },
  ],
  [
    { x: 40, y: 0 },
    { x: 20, y: 20 },
    { x: 40, y: 40 },
    { x: 40, y: 0 },
  ],
]

const DrawerBanner: VFC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { primary, secondary, tertiary } = useBranding()

  useEffect(() => {
    const canvas = canvasRef.current!
    const context = canvas.getContext('2d')!

    const rand = Alea(0)
    //Our first draw

    const drawTri = (
      tri: Triangle,
      color: string,
      xOff: number = 0,
      yOff: number = 0
    ) => {
      context.fillStyle = color
      context.beginPath()
      tri.forEach(pt => context.lineTo(xOff + pt.x, yOff + pt.y))
      context.closePath()
      context.fill()
    }

    context.lineWidth = 2
    context.strokeStyle = 'white'

    let colors = [primary, tertiary, secondary, '#FFFFFF'],
      colorIndex = 0
    const doubleTri = (tri: Triangle): Triangle =>
      // @ts-ignore
      tri.map((pt: Point) => ({ x: 2 * pt.x, y: 2 * pt.y }))
    triangles.forEach(tri => {
      const color = colors[colorIndex++]
      drawTri(doubleTri(tri), color)
    })

    for (let xOff = 0; xOff < 300; xOff += 40) {
      for (let yOff = 0; yOff < 150; yOff += 40) {
        if (xOff >= 80 || yOff >= 80)
          triangles.forEach(tri => {
            const color = colors[Math.floor(rand() * 4)]
            drawTri(tri, color, xOff, yOff)
          })
      }
    }
  }, [primary, secondary, tertiary])

  // Try inline style attrib for pinning opacity to 50% instead of 1%
  return (
    <canvas
      width={300}
      height={160}
      style={{ opacity: 0.5 }}
      className='drawer-banner'
      ref={canvasRef}
    />
  )
}

export default DrawerBanner
