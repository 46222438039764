import React, { FC, useMemo } from 'react'
import { useBranding } from '@paradigms-to-practices/knowhowhub-components'
import { khhBranding } from '../../../../core/constants/config'

const OrganizationLogo: FC<{ size: number; large?: true }> = ({
  size,
  large,
}) => {
  const branding = useBranding()

  return useMemo(() => {
    const src =
      branding.logo !== khhBranding.logo
        ? `https://res.cloudinary.com/paradigms-to-practices/image/upload/c_scale,h_${size}/v1/${branding.logo}`
        : large
        ? khhBranding.largeLogo
        : khhBranding.logo
    return (
      <img
        src={src}
        style={{ height: size }}
        alt={'Organization Logo'}
        className='cursor-pointer'
      />
    )
  }, [size, branding.logo])
}

export default OrganizationLogo
