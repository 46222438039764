import './ResetInitiated.scss'
import React, { FC } from 'react'

export type ResetInitiatedProps = {}
const ResetInitiated: FC<ResetInitiatedProps> = props => {
  return (
    <div className='reset-initiated'>
      <h2>Check your email</h2>
      <h4>We've sent you a password reset email.</h4>
      <h4>Click the button in the email to reset your password.</h4>
    </div>
  )
}
export default ResetInitiated
