export const khhBranding = {
  primary: '#054372',
  secondary: '#1C75BC',
  tertiary: '#00B4EF',
  logo: '/logos/khh-small.svg',
  largeLogo: '/logos/khh-full.svg',
}

export const palette = {
  lime: '#1EDB0A',
  gray: '#6B7280', // gray-500
  lightGray: '#D6D6D6',
  extraLightGray: '#EFEFEF',
  red: '#FF0000',
}

export const operatorId = '5ff52e6e364708a95ce6aac1'

export const authUrl = `https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/app/${process.env.REACT_APP_REALM_APP_ID}/auth/providers/local-userpass/login`
export const anonAuthUrl = `https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/app/${process.env.REACT_APP_REALM_APP_ID}/auth/providers/anon-user/login`
export const refreshUrl =
  'https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/auth/session'
export const gqlUri = `https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/app/${process.env.REACT_APP_REALM_APP_ID}/graphql`
