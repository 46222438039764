import { anonSdk, sdk } from '@paradigms-to-practices/knowhowhub-components'
import {
  action,
  createMachine,
  guard,
  invoke,
  reduce,
  state,
  transition,
} from 'robot3'
import {
  Invitation,
  InvitationDefaultInsertInput,
} from '../../../core/graphql/types.generated'
import { RegisterFormData } from './Register'
import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { AddDetailsErrorCode, addDetailsErrorCode } from './AddDetails'
import { sleep } from '../../../core/util/async'

// const REALM_REGISTER = `https://stitch.mongodb.com/api/client/v2.0/app/${process.env.REACT_APP_REALM_APP_ID}/auth/providers/local-userpass/register`;

export const getInvitationFromToken = async ({ token }: { token: string }) =>
  (await anonSdk.invitationForToken({ input: { token } })).invitationForToken
export const getMyInvitation = async () => {
  const invitation = (await sdk.myInvitation()).myInvitation
  if (!invitation)
    throw new Error('Did not get back an invitation in getMyInvitation')
  if (invitation?.defaults) {
    invitation.defaults = _.fromPairs(
      _.toPairs(invitation.defaults).filter(([, v]) => v !== '{}')
    )
  }
  return invitation
}

class RegisterError extends Error {
  public name: string = 'RegisterError'
  public displayMessage?: string
  private error: string
  private error_code: string
  private link?: string

  constructor(error_code: string, error: string, link?: string) {
    super(error)
    this.error_code = error_code
    this.error = error

    if (error === 'confirmation required')
      this.displayMessage = 'Please contact an administrator'

    if (link) this.link = link
  }
}

type LoginFunc = (
  email: string,
  pass: string,
  remember: boolean
) => Promise<void>
type RegisterFunc = (email: string, pass: string) => Promise<void>
type AuthFuncs = { login: LoginFunc; registerUser: RegisterFunc }
export const register = async ({
  email,
  'new-password': password,
  remember,
  login,
  registerUser,
}: RegisterFormData & AuthFuncs) => {
  const body = JSON.stringify({ email, password })
  console.log(body)

  try {
    await registerUser(email, password)
  } catch (e: any) {
    throw new RegisterError(e.error_code, e.error)
  }

  // const res = await fetch(REALM_REGISTER, {method: "POST", body})
  // if (!res.ok) {
  //   const json: any = await res.json();
  //   throw new RegisterError(json.error_code, json.error);
  // }

  await sleep(1000)

  try {
    return await login(email, password, remember)
  } catch (e: any) {
    if (e.error === 'confirmation required') {
      // Try again
      try {
        return await login(email, password, remember)
      } catch (e: any) {
        throw new RegisterError(e.error_code, e.error)
      }
    }
  }
}

export type AddDetailsData = {
  details: InvitationDefaultInsertInput & { agreement?: boolean }
}
const addDetails = async ({ details }: AddDetailsData) => {
  // Does not need to go to Sentry
  // eslint-disable-next-line no-throw-literal -- Destructured error
  if (!details.agreement)
    throw {
      ...new Error('User must sign agreement'),
      [addDetailsErrorCode]: AddDetailsErrorCode.signAgreement,
      displayMessage: 'User agreements must be accepted',
    }
  delete details.agreement // This doesn't need to be inserted, it can be assumed to be true in Realm
  console.log('addDetails', { details })

  return await sdk.addDetails({ input: details })
}

const resetErrorTransition = (to: string) =>
  transition(
    'resetError',
    to,
    reduce((ctx: object) => ({
      ...ctx,
      error: undefined,
    }))
  )
const errorReducer = reduce((ctx: object, { error }: { error: Error }) => {
  Sentry.captureException(error)
  return { ...ctx, error }
})
const invitationReducer = reduce((ctx: object, ev: { data: Invitation }) => ({
  ...ctx,
  invitation: ev.data,
}))
const saveTokenReducer = reduce((ctx: object, ev: { data: string }) => ({
  ...ctx,
  token: ev.data,
}))
const saveRegistrationDataReducer = reduce(
  (ctx: object, ev: { data: RegisterFormData }) => ({ ...ctx, ...ev.data })
)
const saveDetailsReducer = reduce(
  (ctx: object, ev: { data: InvitationDefaultInsertInput }) => ({
    ...ctx,
    details: ev.data,
  })
)

export type JoinMachineContext = Partial<{
  error: Error
  details: RegisterFormData
  email: string
  password: string
  invitation: Invitation
  token: string
  login: LoginFunc
}>
export const joinMachine = (
  login: LoginFunc,
  registerUser: RegisterFunc,
  logOut: () => Promise<void>
) =>
  createMachine<unknown, JoinMachineContext>(
    {
      init: state(
        // If we got an invitation token, fetch that invitation to check if it's been accepted
        transition('invitationToken', 'getInvitation', saveTokenReducer),

        // If we had no invitation token but did have an access token, then the user is registered
        // and we need to check if they have accepted their invitation
        transition('accessToken', 'getMyInvitation'),

        // If we have no invitation token and no auth token, send user to login in case they were
        // redirected here after registering but weren't able to auth in for the first time,
        // or in case the user is resuming an interrupted registration flow
        transition('noTokens', 'redirectToAuth', action(logOut))
      ),

      // No transitions, these just render a redirect
      redirectToAuth: state(),
      redirectToTokenless: state(),

      // States when path contains token (before registration)
      getInvitation: invoke(
        getInvitationFromToken,
        // If there's an accepted date on the invitation, it's already been claimed, go to addDetails
        transition(
          'done',
          'redirectToAuth',
          invitationReducer,
          guard((ctx: object, ev: any) => Boolean(ev.data.completed))
        ),
        transition(
          'done',
          'redirectToTokenless',
          invitationReducer,
          guard((ctx: object, ev: any) => Boolean(ev.data.accepted))
        ),
        // Else the invitation is unclaimed and we should first register
        transition('done', 'register', invitationReducer),
        transition('error', 'getInvitationError', errorReducer)
      ),
      register: state(
        transition('register', 'registering', saveRegistrationDataReducer)
      ),
      registering: invoke(
        register,
        transition('done', 'redirectToTokenless'),
        transition('error', 'registerError', errorReducer)
      ),

      // States when path has no token (after registration AND login)
      getMyInvitation: invoke(
        getMyInvitation,
        transition(
          'done',
          'redirectToAuth',
          invitationReducer,
          guard((ctx: object, ev: any) => Boolean(ev.data.completed))
        ),
        transition('done', 'addDetails', invitationReducer),
        transition('error', 'getInvitationError', errorReducer)
      ),
      addDetails: state(
        transition('addDetails', 'addingDetails', saveDetailsReducer)
      ),
      addingDetails: invoke(
        addDetails,
        transition('done', 'addedDetails'),
        transition('error', 'addDetailsError', errorReducer)
      ),
      addedDetails: state(),

      getInvitationError: state(),
      registerError: state(
        transition('retryRegister', 'registering'),
        resetErrorTransition('register')
      ),
      addDetailsError: state(
        transition('retryAddDetails', 'addingDetails'),
        resetErrorTransition('addDetails')
      ),
    },
    () => ({ login, registerUser })
  )
