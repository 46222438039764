import React, { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useKhh } from '@paradigms-to-practices/knowhowhub-components'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PublicRoute: FC<any> = ({
  component: Component,
  children,
  ...rest
}) => {
  const { user, isAuthenticated } = useKhh()
  return (
    <Route
      {...rest}
      render={({ location, ...props }) =>
        isAuthenticated ? (
          (() => {
            console.log(
              'Public route was visited while authenticated, sending to dashboard...'
            )
            return (
              <Redirect
                to={{
                  pathname: user?.admin ? '/teacher' : '/learner',
                  state: { from: location },
                }}
              />
            )
          })()
        ) : Component ? (
          <Component {...props} {...rest} />
        ) : (
          children
        )
      }
    />
  )
}

export default PublicRoute
