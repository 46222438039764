import React, { useEffect, useState, VFC } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { joinMachine, JoinMachineContext } from './machine'
import { useMachine } from 'react-robot'
import Register, { RegisterFormError } from './Register'
import AddDetails, { AddDetailsFormError } from './AddDetails'
import _ from 'lodash'
import {
  Loading,
  useKhh,
  validAccessToken,
} from '@paradigms-to-practices/knowhowhub-components'
import { Machine } from 'robot3'
import Welcome from './Welcome'
import './Join.scss'
import Pane from 'src/core/components/Pane'

const Join: VFC = () => {
  const { logIn, registerUser, logOut, refresh } = useKhh()
  const location = useLocation()

  const [machine, setMachine] = useState<Machine<{}, JoinMachineContext>>(
    joinMachine(logIn, registerUser, logOut)
  )

  // This is the opposite of useEffect(()=>{},[]) in that it runs the effect every time
  // we aren't on the first render. Because location is the only dependency of the second
  // useEffect, we check if it's the first render iff the location changed and reset the
  // machine if it's not the first render.
  const [firstRender, setFirstRender] = useState<boolean>(true)
  useEffect(() => {
    setFirstRender(false)
  }, [])
  useEffect(() => {
    if (!firstRender) setMachine(joinMachine(logIn, registerUser, logOut))
    // eslint-disable-next-line
  }, [location])

  const [current, send] = useMachine<Machine<{}, JoinMachineContext>>(machine)
  const token = new URLSearchParams(location.search).get('t')

  useEffect(() => {
    if (token) return send({ type: 'invitationToken', data: token })
    if (validAccessToken()) return send('accessToken')
    send('noTokens')
  }, [send, token])

  console.log({ current })

  return (
    <Pane>
      {/*{current.name}*/}
      {(() => {
        switch (current.name) {
          case 'init':
          case 'getMyInvitation':
            return <Loading />

          case 'redirectToAuth':
            console.debug('Redirecting to login from join')
            return (
              <>
                <Loading />
                <Redirect to='/login' />
              </>
            )
          case 'redirectToTokenless':
            console.debug('Redirecting to tokenless join from join')
            return (
              <>
                <Loading />
                <Redirect to='/join' />
              </>
            )

          case 'getInvitation':
            return <Loading />
          case 'getInvitationError':
            return (
              <div>
                <h2>We encountered an error</h2>

                {
                  //@ts-ignore
                  current.context.error?.response?.errors?.[0]?.message ? (
                    <h4 id='err-msg'>
                      {
                        JSON.parse(
                          //@ts-ignore
                          current.context.error!.response.errors[0].message
                        ).message
                      }
                    </h4>
                  ) : (
                    //@ts-ignore
                    <h4 id='err-msg'>An unknown error occurred</h4>
                  )
                }
              </div>
            )
          case 'register':
          case 'registering':
          case 'registerError':
            return (
              <Register
                email={current.context.invitation!.email}
                registering={current.name === 'registering'}
                resetError={() => send('resetError')}
                handleRegister={data => send({ type: 'register', data })}
                error={current.context.error as RegisterFormError | undefined}
              />
            )

          case 'addDetails':
          case 'addingDetails':
          case 'addDetailsError':
            return (
              <AddDetails
                initialData={_.fromPairs(
                  _.toPairs(current.context.invitation!.defaults!).filter(
                    ([, v]) => Boolean(v)
                  )
                )}
                addingDetails={current.name === 'addingDetails'}
                handleAddDetails={data => send({ type: 'addDetails', data })}
                resetError={() => send('resetError')}
                error={current.context.error as AddDetailsFormError | undefined}
              />
            )
          case 'addedDetails':
            return <Welcome refresh={refresh} />
        }
      })()}
    </Pane>
  )
}

export default Join
