import { useLayoutEffect, useState } from 'react'

export type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export function getBreakpointFromWidth(width: number): Breakpoint {
  if (width < 640) return 'sm'
  if (width < 768) return 'md'
  if (width < 1024) return 'lg'
  if (width < 1280) return 'xl'
  return '2xl'
}

export function useWindowWidth() {
  const [width, setWidth] = useState<number>(window.innerWidth)
  useLayoutEffect(() => {
    function updateSize() {
      const currWidth = window.innerWidth
      if (width !== currWidth) setWidth(width)
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [width])
  return width
}

export function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(
    getBreakpointFromWidth(window.innerWidth)
  )
  useLayoutEffect(() => {
    function updateSize() {
      const currBreakpoint = getBreakpointFromWidth(window.innerWidth)
      if (breakpoint !== currBreakpoint) setBreakpoint(currBreakpoint)
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [breakpoint])
  return breakpoint
}
