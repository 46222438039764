import React, { VFC } from 'react'
import { useKhh } from '@paradigms-to-practices/knowhowhub-components'
import './profileIcon.css'

const ProfileIcon: VFC = () => {
  const realmApp = useKhh()
  return (
    <div className='profile-letter'>{realmApp.user?.firstName.charAt(0)}</div>
  )
}

export default ProfileIcon
