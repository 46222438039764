import {
  BooleanInput,
  Button,
  FormError,
  FormProps,
  StringInput,
  TimezoneInput,
} from '@paradigms-to-practices/knowhowhub-components'
import React, { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import './AddDetails.scss'
// @ts-ignore
import Iubenda from 'react-iubenda-policy'
import FormErrorComponent from '../../../../core/components/FormErrorComponent/FormErrorComponent'

export const addDetailsErrorCode: unique symbol = Symbol('addDetailsErrorCode')

export enum AddDetailsErrorCode {
  signAgreement = 'SIGN_AGREEMENT',
  invalidToken = 'INVALID_TOKEN',
  networkError = 'NETWORK_ERROR',
}

export interface AddDetailsFormError extends FormError<AddDetailsFormData> {
  [addDetailsErrorCode]: AddDetailsErrorCode
}

export type AddDetailsFormData = {
  firstName: string
  lastName: string
  timezone: string
  agreement: boolean
}

export interface AddDetailsFormProps
  extends FormProps<AddDetailsFormData, AddDetailsFormError> {
  addingDetails: boolean
  handleAddDetails: (data: AddDetailsFormData) => void
}

const AddDetailsFormBody: FC<
  Pick<AddDetailsFormProps, 'error' | 'resetError'>
> = React.memo(
  props => {
    const { error, resetError } = props
    const policy = 25329706

    // const methods = useFormContext<AddDetailsFormData>();
    // const {errors} = useFormState<AddDetailsFormData>({control: methods.control});
    return (
      <div className='flex flex-col space-y-4' key='add-details-body'>
        <StringInput<AddDetailsFormData>
          name='firstName'
          type='text'
          label='First Name'
          required
          error={error}
          resetError={resetError}
        />

        <StringInput<AddDetailsFormData>
          name='lastName'
          type='text'
          label='Last Name'
          required
          error={error}
          resetError={resetError}
        />

        <TimezoneInput required error={error} resetError={resetError} />

        <div className='flex flex-col space-y-8 p-4 text-sm'>
          <div
            className='flex flex-col items-center'
            style={{ color: 'var(--primary)' }}
          >
            <div className='hover-105'>
              <Iubenda
                id={policy}
                type='terms-and-conditions'
                styling='nostyle'
              >
                Terms and conditions
              </Iubenda>
            </div>

            <div className='hover-105'>
              <Iubenda id={policy} type='privacy' styling='nostyle'>
                Privacy Policy
              </Iubenda>
            </div>

            <div className='hover-105'>
              <Iubenda id={policy} type='cookie' styling='nostyle'>
                Cookie Policy
              </Iubenda>
            </div>
          </div>
        </div>

        <BooleanInput
          name='agreement'
          label='I agree to the above agreements'
          error={error}
          resetError={resetError}
          required
        />
      </div>
    )
  },
  (prevProps, nextProps) => prevProps.error === nextProps.error
)

const AddDetails: FC<AddDetailsFormProps> = props => {
  const { initialData, error, resetError, addingDetails, handleAddDetails } =
    props
  const opts = {
    defaultValues: {
      timezone: Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone,
      ...initialData,
    },
  }

  const methods = useForm<AddDetailsFormData>(opts)
  const onSubmit = methods.handleSubmit(handleAddDetails)

  return (
    <FormProvider<AddDetailsFormData> {...methods}>
      <form
        onSubmit={onSubmit}
        className={`add-details is-valid ${error ? 'error' : ''}`}
      >
        <h2>One more thing...</h2>
        <h4>Tell us about yourself</h4>

        <AddDetailsFormBody error={error} resetError={resetError} />

        <FormErrorComponent error={error} />
        <Button
          label={addingDetails ? 'Submitting...' : 'Submit'}
          rank='pane'
          type='submit'
          isLoading={addingDetails}
          className='border-none'
        />
      </form>
    </FormProvider>
  )
}

export default AddDetails
