import create from 'zustand/vanilla'

export const widthStore = create<{ width: number }>(() => ({
  width: window.innerWidth,
}))

// No need to clean this up, should always remain attached
window.addEventListener('resize', () => {
  widthStore.setState({ width: window.innerWidth })
})
