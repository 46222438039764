import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Button } from '@paradigms-to-practices/knowhowhub-components'

type FormWithNewPassword = { 'new-password': string }
export const SetPasswordButton: FC<{ running: boolean }> = ({ running }) => {
  const { control } = useFormContext<FormWithNewPassword>()
  const value = useWatch<FormWithNewPassword>({ name: 'new-password', control })
  const enabled = value && value.length >= 8 && value.length <= 64

  return (
    <Button
      type='submit'
      rank='pane'
      label={running ? 'Setting Password...' : 'Set Password'}
      disabled={!enabled}
      className='mt-8'
      isLoading={running}
    />
  )
}
