import React, { FC } from 'react'
import { useKhh } from '@paradigms-to-practices/knowhowhub-components'
import './profileHeader.css'
import ProfileIcon from '../ProfileIcon'

const ProfileHeader: FC<{
  isOpen?: boolean
  className?: string
}> = ({ isOpen = false, className }) => {
  const realmApp = useKhh()
  return (
    <div className={`profile-header ${className ?? ''}`}>
      <div className='profile-header-text'>
        <div className='font-medium whitespace-nowrap overflow-ellipsis'>
          {realmApp.user?.firstName + ' ' + realmApp.user?.lastName}
        </div>
        <div className='text-gray-500 whitespace-nowrap overflow-ellipsis'>
          {realmApp.organization?.name}
        </div>
      </div>
      <ProfileIcon />
    </div>
  )
}

export default ProfileHeader
