import React, { FC } from 'react'
import { Nugget } from '../../../../core/graphql/graphql.generated'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { UseLearnerStore } from '../store'
import sanitizeHtml from 'sanitize-html'
import { byOrderThenDelay } from '../../../../core/util/sorting'
import {
  ErrorComponent,
  List,
  LoadingList,
} from '@paradigms-to-practices/knowhowhub-components'
import './learnerDashboardThings.css'

export type LearnerDashboardThingsProps = { useLearnerState: UseLearnerStore }

const LearnerDashboardThings: FC<LearnerDashboardThingsProps> = ({
  useLearnerState,
}) => {
  const { things, fetchThings } = useLearnerState()
  const selectedBundleId: string | null =
    useRouteMatch<{ id: string }>(`/learner/:id`)!.params.id
  const location = useLocation()

  const thingsForThisBundle = things[selectedBundleId]

  // Catches undefined and null, if undefined the root dashboard component will load it for us
  if (!thingsForThisBundle) return <LoadingList />
  if (thingsForThisBundle instanceof Error) return <ErrorComponent />
  const highlightedId: string | undefined =
    new URLSearchParams(location.search).get('selected') || undefined
  const highlighted: Nugget | undefined = thingsForThisBundle.find(
    thing => thing._id === highlightedId
  )

  return (
    <List<Nugget>
      {...{
        Placeholder: () => (
          <div className='list-placeholder'>
            There are no things in this bundle yet
          </div>
        ),
        selected: undefined,
        highlighted,
        onClick: nugget => nugget.url && window.open(nugget.url),
        items: thingsForThisBundle.sort(byOrderThenDelay),
        titleFunc: (nug: Nugget) => nug.title,
        selectableBySearch: true,
        refresh: async () =>
          selectedBundleId && (await fetchThings(selectedBundleId)),
        descriptionFunc: (nug: Nugget) =>
          nug.descriptionIsHtml ? (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(nug.description),
              }}
            />
          ) : (
            <span>{nug.description}</span>
          ),
      }}
    />
  )
}

export default LearnerDashboardThings
