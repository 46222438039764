import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'src/index.scss'
import dayjs from 'dayjs'

import {
  refreshAccessToken,
  validRefreshToken,
} from '@paradigms-to-practices/knowhowhub-components'

import calendar from 'dayjs/plugin/calendar'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin

dayjs.extend(calendar)
dayjs.extend(utc)
dayjs.extend(timezone)

// Register service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js')
  })
}

// if (process.env.NODE_ENV !== 'development') {
import('@sentry/browser').then(Sentry =>
  import('@sentry/tracing').then(({ Integrations }) =>
    Sentry.init({
      dsn: 'https://5b018a1e59ff4cd095239ae94acc6340@o544304.ingest.sentry.io/5665442',
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: process.env.REACT_APP_REALM_APP_ID,
      release: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,
    })
  )
)
// }

if (validRefreshToken()) refreshAccessToken().then()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
