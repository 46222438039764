import React, { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useKhh } from '@paradigms-to-practices/knowhowhub-components'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const ProtectedRoute: FC<any> = ({
  component: Component,
  children,
  ...rest
}) => {
  const realmApp = useKhh()
  return (
    <Route
      {...rest}
      render={({ location, ...props }) => {
        if (!realmApp.isAuthenticated) {
          console.info(
            'Protected route ' +
              location.pathname +
              ' was visited while not authenticated, sending to login...'
          )
          return (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          )
        }
        if (!realmApp.user) {
          console.info(
            'User has not added their details, redirecting to join...'
          )
          return (
            <Redirect to={{ pathname: '/join', state: { from: location } }} />
          )
        }
        return Component ? <Component {...props} {...rest} /> : children
      }}
    />
  )
}

export default ProtectedRoute
