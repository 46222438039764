import { motion } from 'framer-motion'
import React, { FC, useEffect } from 'react'
import './drawer.css'
import ProfileHeader from 'src/features/navigation/profile/ProfileHeader'
import DrawerBanner from '../DrawerBanner'
import { useIsMobile } from '../../../../core/hooks/useIsMobile'
import { Buttons } from '../../Buttons'

const Drawer: FC<{ close: () => void }> = ({ close }) => {
  const isMobile = useIsMobile()
  useEffect(() => {
    if (!isMobile) close()
  }, [isMobile, close])

  return (
    <>
      <motion.div
        drag='x'
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={{ right: 0, left: 0.2 }}
        dragMomentum={false}
        onDragEnd={(event, info) => {
          if (info.offset.x < -100) close()
        }}
        id='drawer'
        key='drawer'
        className='drawer'
      >
        <div className='drawer-header'>
          <ProfileHeader className='drawer-profile-header' />
          <DrawerBanner />
        </div>
        <div className='drawer-body'>
          <Buttons close={close} />
        </div>
      </motion.div>
      <motion.div
        initial={{ backgroundColor: '#00000000' }}
        animate={{ backgroundColor: '#00000040' }}
        exit={{ backgroundColor: '#00000000' }}
        transition={{ duration: 0.2 }}
        drag='x'
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={{ right: 0, left: 0.2 }}
        dragMomentum={false}
        onDragEnd={(event, info) => {
          if (info.offset.x < -100) close()
        }}
        id='scrim'
        key='scrim'
        onClick={close}
        className='scrim'
      />
    </>
  )
}

export default Drawer
